<template>
  <div class="card text-left">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Update email contents</h3>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form @submit.prevent="submitHandler()" novalidate>
        <div class="pl-lg-4">
          <div class="form-group" v-for="(field, i) in schema" :key="i">
            <label class="form-control-label">{{ field.label }}</label>
            <editor
              class="form-control"
              :id="`ckeditor-${i}`"
              v-model="form[field.model]"
              :api-key="ckeditor.API_KEY"
              :init="ckeditor.config"
            >
            </editor>

            <div
              class="text-dark font-12 mt-1 bg-light p-2 rounded"
              v-html="field.notes"
            ></div>
            <div
              v-if="submitted && getError(field.model)"
              class="text-danger font-12"
            >
              required
            </div>
          </div>
        </div>

        <div class="pl-lg-4">
          <button class="btn btn-primary" type="submit">Submit</button>
          <div v-if="submitted && this.$v.$invalid" class="text-danger">
            Please fix highlighted errors
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Schema from "@/store/in-memory-db/forms/emails.json";
import Editor from "@tinymce/tinymce-vue";
import CkConfig from "@/json/ckEditor.js";
import { mapGetters } from "vuex";

export default {
  name: "AdminPagesEdit",
  components: {
    editor: Editor,
  },
  data() {
    return {
      submitted: false,
      schema: Schema,
      editors: {},
      form: {},
      validations: {},
      message: null,
      metaData: {},
      ckeditor: CkConfig(),
    };
  },

  validations() {
    return {
      form: this.fieldsValidation,
    };
  },

  computed: {
    ...mapGetters("common", ["getEmails"]),

    fieldsValidation() {
      const items = {};
      for (const info of this.schema) {
        items[info.model] = { required };
      }
      return items;
    },
  },

  mounted() {
    this.$store.dispatch("common/getEmailContents").then(() => {});
  },

  watch: {
    getEmails: function(val) {
      this.form = JSON.parse(JSON.stringify(val));
    },
  },

  methods: {
    getError(field) {
      return this.$v.form[field].$invalid;
    },

    submitHandler() {
      // this.submitted = true;
      // this.$v.$touch();
      // if (this.$v.$invalid) return;

      this.$store.dispatch("common/updateEmailContents", this.form);
    },
  },
};
</script>

<style scoped>
.font-12 {
  font-size: 12px;
}

.bg-light {
  background-color: #f7f7f7 !important;
}
</style>
